import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Config } from '../../common/config';
import './style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class ConsignmentPlacePage extends Component {
    constructor(props) {
        super(props);

        this.orderNumber = props.match.params.orderNumber

        this.state = {
            selectedPlaceGroup: null,
            selectedEntranceGroup: null,
            entranceMethod: null,
            visibleEtcPlaceInput: false,
            etcPlaceGroupMethod: null,
            loadingSave: false,
            openAlert: false,
            alertTitle: '',
            alertMessage: '',
            isValidOrderNumber: !this.isEmpty(this.orderNumber),
            isAddedPlaceDetail: false,
            receiverAddress: '',
            receiverAddrDetail: '',
        };
    }

    // TODO
    getParams = (findValue) => {
        let search = this.props.location.search
        var params = search.substr(search.indexOf("?") + 1);
        var sval = "";
        params = params.split("&");
        for (var i = 0; i < params.length; i++) {
            var temp = params[i].split("=");
            if (temp[0] === findValue) {
                sval = temp[1];
            }
        }
        return sval;
    }

    componentDidMount() {
        if (!this.isEmpty(this.orderNumber)) {
            this.fetchTackOrder();
            this.fetchPlaceDetail();
        }
    }

    async fetchTackOrder() {
        let url = Config.api.cs_url + "/order/tracking/" + this.orderNumber;

        try {
            const response = await fetch(url, {
                method: "GET",
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            })
            if (!response.ok) { throw response }
            const body = await response.json()

            if (body.result === 'Y') {
                if (body.complete === true) {
                    // Redirect
                    window.location.replace(Config.api.track_url + '/?trackingNumber=' + this.orderNumber);
                }

                let address = body['receiverAddr']
                if (address) {
                    let splittedAddress = address.split(" ")
                    const spitAddressCount = splittedAddress.length
                    if (spitAddressCount >= 2) {
                        address = splittedAddress.slice(spitAddressCount - 2, spitAddressCount).join(" ")
                        if (address) {
                            address = " (" + address + ")"
                        }
                    }
                } else {
                    address = ''
                }

                this.setState({
                    receiverAddress: body['receiverRoadAddr'] + address,
                    receiverAddrDetail: body['receiverAddrDetail']
                });
            } else {
                // TODO Error
                this.setState({
                    isValidOrderNumber: false,
                });
            }
        } catch (err) {
            console.log(err)

            this.setState({
                isValidOrderNumber: false,
            });
        }
    }

    async fetchPlaceDetail() {
        let url = Config.api.cs_url + "/order/place-detail/" + this.orderNumber;

        try {
            const response = await fetch(url, {
                method: "GET",
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            })

            this.setState({
                isAddedPlaceDetail: response.ok
            });
        } catch (err) {
            console.log(err)
        }
    }

    onChangedPlaceGroup = (data) => {
        let visibleEtcPlaceInput = data.target.value === 'etc'

        this.setState({
            selectedPlaceGroup: data.target.value,
            visibleEtcPlaceInput: visibleEtcPlaceInput,
        })
    }

    onChangedEtcPlaceMethod = (data) => {
        this.setState({
            etcPlaceGroupMethod: data.target.value,
        })
    }

    onChangedEntranceGroup = (data) => {
        this.setState({
            selectedEntranceGroup: data.target.value,
        })
    }

    onChangedEntranceMethod = (data) => {
        this.setState({
            entranceMethod: data.target.value,
        })
    }

    handleAlertClose = () => {
        this.setState({
            openAlert: false,
        })
    }

    openAlertDialog = (title, message) => {
        this.setState({
            openAlert: true,
            alertTitle: title,
            alertMessage: message,
        });
    }

    onClickedSave = () => {
        var isValidInputLength = true
        // 위탁배송 장소
        let place;
        switch (this.state.selectedPlaceGroup) {
            case 'door':
                place = "문 앞";
                break;
            case 'security_office':
                place = "경비실";
                break;
            case 'delivery_box':
                place = "택배함";
                break;
            case 'etc':
                place = this.state.etcPlaceGroupMethod;
                if (place.length > 20) {
                    isValidInputLength = false
                }
                break;
            default:
                place = null;
                break;
        }

        if (this.isEmpty(place)) {
            // TODO Error
            return;
        }

        // 공동현관 출입방법
        let entrance_method;
        switch (this.state.selectedEntranceGroup) {
            case 'open':
                entrance_method = "자유 출입 가능";
                break;
            case 'entrance_method':
                entrance_method = this.state.entranceMethod;
                if (entrance_method.length > 20) {
                    isValidInputLength = false
                }
                break;
            default:
                entrance_method = null;
                break;
        }

        if (this.isEmpty(entrance_method)) {
            return;
        }

        if (isValidInputLength === false) {
            this.openAlertDialog('', "입력하신 내용은 20자 이내로 작성해주시기 바랍니다");
            return;
        }

        this.setState({
            loadingSave: true,
        });

        let url = Config.api.cs_url + "/order/place-detail"

        fetch(url, {
            method: "POST",
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'orderNumber': this.orderNumber,
                'deliveryLocation': place,
                'commonEntrance': entrance_method,
            })
        }).then(resp => {
            if (resp.ok) { return resp.json() }
            else { throw resp }
        }).then(body => {
            this.openAlertDialog('', "응답해 주셔서 감사합니다.\n고객님의 물품을 안전하게 배송하겠습니다.");
            // Init State
            this.setState({
                selectedPlaceGroup: null,
                selectedEntranceGroup: null,
                entranceMethod: null,
                visibleEtcPlaceInput: false,
                etcPlaceGroupMethod: null,
                loadingSave: false,
                isAddedPlaceDetail: true,
            });
            document.getElementById('input_entrance_method').value = '';
        }).catch(error => {
            this.setState({
                loadingSave: false,
            });

            let body = {}
            if (error.json) {
                body = error.json()
            } else {
                console.log(error)
            }

            this.openAlertDialog('알림', 'message' in body ? body.message : '오류가 발생했습니다.');
        })
    }

    onFocusInputEntranceMethod = () => {
        if (this.state.selectedEntranceGroup !== 'entrance_method') {
            this.setState({
                selectedEntranceGroup: 'entrance_method',
            });
        }
    }

    isEnableButtonCondition = (placeGroup, entranceGroup, entranceMethod, etcPlaceGroupMethod) => {
        let isValidEntranceGroup
        if (entranceGroup === 'entrance_method') {
            isValidEntranceGroup = !this.isEmpty(entranceMethod)
        } else if (entranceGroup) {
            isValidEntranceGroup = true
        } else {
            isValidEntranceGroup = false
        }

        let isValidPlaceGroup
        if (placeGroup === 'etc') {
            isValidPlaceGroup = !this.isEmpty(etcPlaceGroupMethod)
        } else if (placeGroup) {
            isValidPlaceGroup = true
        } else {
            isValidPlaceGroup = false
        }

        return isValidPlaceGroup && isValidEntranceGroup
    }

    isEmpty = (value) => {
        if (value === "" || value == null || value === undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) {
            return true
        } else {
            return false
        }
    };

    render() {
        document.querySelector("title").innerText = "핑퐁 위탁배송 장소 지정"

        return (
            <div>
                <header>
                    <div className="title">핑퐁</div>
                </header>
                <main>
                    {this.state.isValidOrderNumber === false ?
                        <div className="invalid_order">
                            <div className="place_info_text">배송 정보를 조회하지 못했습니다. 송장번호를 다시 확인해 주세요.</div>
                        </div>
                        :
                        <div className='valid_order'>
                            <div className="place_info">
                                <div className="place_info_text">대규모 오피스 건물, 공동주택은 구체적인 배송지 정보가 없을경우 배송(수거)이 불가할 수 있습니다. 신속하고 안전한 배송을 위해, 배송장소 및 출입방법을 입력해주세요.</div>
                            </div>
                            {this.isEmpty(this.state.receiverAddress) === false ?
                                <div className="receiver_address">배송지: {this.state.receiverAddress}</div>
                                :
                                <div />
                            }
                            {
                                this.isEmpty(this.state.receiverAddrDetail) === false ?
                                    <div className="receiver_address_detail">{this.state.receiverAddrDetail}</div>
                                    :
                                    <div />
                            }
                            {this.state.isAddedPlaceDetail === true ?
                                <div className='saved_info'>이미 장소 정보가 저장되었습니다. 변경이 필요하신 경우, 새롭게 작성해 주세요.</div>
                                :
                                <div />
                            }
                            <div className='delivery_location_container'>
                                <input id="rbtn_door"
                                    className='radio'
                                    name="place"
                                    value="door"
                                    type="radio"
                                    readOnly={true}
                                    checked={this.state.selectedPlaceGroup === 'door'}
                                    onClick={this.onChangedPlaceGroup}
                                />
                                <label htmlFor="rbtn_door">
                                    <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_door" />
                                    <div className="label_font">문 앞</div>
                                </label>
                                <input id="rbtn_security_office"
                                    value="security_office"
                                    className='radio'
                                    name="place"
                                    type="radio"
                                    readOnly={true}
                                    checked={this.state.selectedPlaceGroup === 'security_office'}
                                    onClick={this.onChangedPlaceGroup}
                                />
                                <label htmlFor="rbtn_security_office">
                                    <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_security_office" />
                                    <div className="label_font">경비실</div>
                                </label>
                                <input id="rbtn_delivery_box"
                                    value="delivery_box"
                                    className='radio'
                                    name="place"
                                    type="radio"
                                    readOnly={true}
                                    checked={this.state.selectedPlaceGroup === 'delivery_box'}
                                    onClick={this.onChangedPlaceGroup}
                                />
                                <label htmlFor="rbtn_delivery_box">
                                    <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_delivery_box" />
                                    <div className="label_font">택배함</div>
                                </label>
                                <input id="rbtn_etc"
                                    className='radio'
                                    value="etc"
                                    name="place"
                                    type="radio"
                                    readOnly={true}
                                    checked={this.state.selectedPlaceGroup === 'etc'}
                                    onClick={this.onChangedPlaceGroup}
                                />
                                <label htmlFor="rbtn_etc">
                                    <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_etc" />
                                    <div className="label_font">기타장소</div>
                                </label>
                                {this.state.visibleEtcPlaceInput ?
                                    <input id="input_etc_place"
                                        className='input_etc_place'
                                        type="text"
                                        placeholder="장소만입력"
                                        onChange={this.onChangedEtcPlaceMethod} />
                                    :
                                    <div />
                                }
                            </div>
                            <div className="entrance_title">공동현관 출입방법</div>
                            <div className="entrance_title_line" />
                            <div className='entrance_container'>
                                <input id="rbtn_entrance_method"
                                    value="entrance_method"
                                    className='radio'
                                    name="entrance"
                                    type="radio"
                                    readOnly={true}
                                    checked={this.state.selectedEntranceGroup === 'entrance_method'}
                                    onClick={this.onChangedEntranceGroup}
                                />
                                <label htmlFor="rbtn_entrance_method">
                                    <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_entrance_method" />
                                    <input className="input_box"
                                        id="input_entrance_method"
                                        type="text"
                                        placeholder="예: #1234* (20자 이내)"
                                        onFocus={this.onFocusInputEntranceMethod}
                                        onChange={this.onChangedEntranceMethod}
                                    />
                                </label>
                                <input id="rbtn_open"
                                    value="open"
                                    className='radio'
                                    name="entrance"
                                    type="radio"
                                    readOnly={true}
                                    checked={this.state.selectedEntranceGroup === 'open'}
                                    onClick={this.onChangedEntranceGroup}
                                />
                                <label htmlFor="rbtn_open">
                                    <img src="https://cdn.0br1.io/img/icons/circle_72x72.png" alt="checked_entrance_open" />
                                    <div className='label_font'>자유 출입 가능</div>
                                </label>
                            </div>
                            <div className='notice_container'>
                                <div className='notice_title'>
                                    <img src="https://cdn.0br1.io/img/icons/aler_circle_72x72.png" alt="alert_icon" />
                                    <div className="notice_title_font">확인해주세요!</div>
                                </div>
                                <div className="notice_desc_font">비밀번호가 정확하지 않은 경우, 부득이하게 1층 공동현관 앞 또는 경비실 앞에 배송될 수 있습니다.</div>
                                <div className="notice_desc_font">배송지 변경 등의 요청사항은 반영되지 않습니다.</div>
                            </div>
                        </div>
                    }
                </main>
                {this.state.isValidOrderNumber === true ?
                    <footer>
                        {
                            this.state.loadingSave === true ?
                                <div className='spinner_container'>
                                    <CircularProgress className="spinner" size='50px' />
                                </div>
                                : <button
                                    className="button"
                                    type="button"
                                    disabled={!this.isEnableButtonCondition(
                                        this.state.selectedPlaceGroup,
                                        this.state.selectedEntranceGroup,
                                        this.state.entranceMethod,
                                        this.state.etcPlaceGroupMethod,
                                    )}
                                    onClick={() => this.onClickedSave()}>
                                    동의하고 저장하기
                                </button>
                        }
                    </footer>
                    :
                    <div />
                }
                <Dialog
                    open={this.state.openAlert}
                    onClose={this.handleAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.alertTitle == null ? '' : this.state.alertTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{this.state.alertMessage}</div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertClose}>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConsignmentPlacePage;