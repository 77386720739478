import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Config } from '../../../common/config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import './style.css';
import imageCompression from 'browser-image-compression';

class QnaSameDayWrite extends Component {
    constructor(props) {
        super(props);

        this.handleImageUpload = this.handleImageUpload.bind(this)
        this.state = {
            isValidRegister: false,
            loadingSave: false,
            qna: {
                name: "",
                mobileNumber: "",
                type: { value: "DELIVERY", label: "픽업/배송" }, // 타입 고정
                content: "",
            },
            selectedImage: null,
        };

        this.callBack = null;
    }

    onChangeQnaType = (event) => {
        let qna = this.state.qna
        qna['type'] = event

        this.setState({ qna: qna });
    }

    onChangeQna = (event) => {
        let qna = this.state.qna
        let eventName = event.target.name

        if (eventName === 'mobileNumber') {
            qna['mobileNumber'] = (event.target.validity.valid) ? event.target.value : this.state.qna['mobileNumber']
        } else {
            qna[event.target.name] = event.target.value.trim()
        }

        this.setState({ qna: qna });
    }

    onClickedRegisteQna() {
        const url = Config.api.cs_url + "/inquiry"

        this.setState({ loadingSave: true });

        const qna = this.state.qna

        let pictures = []
        if (this.state.selectedImage) {
            pictures = [this.state.selectedImage]
        }

        fetch(url, {
            method: "POST",
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                name: qna.name,
                mobileNumber: qna.mobileNumber,
                type: qna.type.value,
                question: qna.content,
                orderNumber: this.props.orderNumber,
                pictures: pictures
            })
        }).then(resp => {
            if (resp.ok) { return resp.json() }
            else { throw resp }
        }).then(body => {
            this.callBack = () => {
                this.props.moveList();
            }
            this.openAlertDialog('', "문의가 정상적으로 등록되었습니다.");

            this.setState({
                isValidRegister: false,
                loadingSave: false,
                qna: {
                    name: "",
                    mobileNumber: "",
                    type: { value: "DELIVERY", label: "픽업/배송" }, // 타입 고정
                    content: "",
                }
            })

            document.getElementById('name').value = '';
            document.getElementById('mobileNumber').value = '';
            document.getElementById('content').value = '';
        }).catch(error => {
            this.setState({ loadingSave: false });

            let body = {}
            if (error.json) {
                body = error.json()
            } else {
                console.log(error)
            }

            this.openAlertDialog('알림', 'message' in body ? body.message : '오류가 발생했습니다.');
        })
    }

    isEnableButtonCondition = (qna) => {
        // TODO check mobile number pattern
        let name = qna['name']
        let mobileNumber = qna['mobileNumber']
        let content = qna['content']

        return !this.isEmpty(name) && !this.isEmpty(mobileNumber) && !this.isEmpty(content)
    }

    isEmpty = (value) => {
        if (value === "" || value == null || value === undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) {
            return true
        } else {
            return false
        }
    };

    handleAlertClose = () => {
        this.setState({
            openAlert: false,
        })

        if (this.callBack != null) {
            this.callBack();
            this.callBack = null;
        }
    }

    openAlertDialog = (title, message) => {
        this.setState({
            openAlert: true,
            alertTitle: title,
            alertMessage: message,
        });
    }

    onChangeImageUpload = (e) => {
        const { files } = e.target;
        const uploadFile = files[0];

        if (uploadFile === undefined) {
            this.setState({ selectedImage: null })
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(uploadFile);
            reader.onloadend = (fileReaderEvent) => {
                const imageAsBase64 = fileReaderEvent.target.result;
                const image = document.createElement("img");
                image.src = imageAsBase64;

                const imageResizeWidth = 400;

                const canvas = document.createElement('canvas');
                canvas.width = imageResizeWidth;
                canvas.height = ~~(image.height * (imageResizeWidth / image.width));
                const context = canvas.getContext('2d', { alpha: false });
                context.drawImage(image, 0, 0, canvas.width, canvas.height);

                const resizedImageAsBase64 = canvas.toDataURL(undefined);
                let extension = uploadFile.type.replace("image/", "")
                console.log(extension)

                let selectedImage = {
                    'image': resizedImageAsBase64,
                    'extension': extension
                }

                console.log(selectedImage)

                this.setState({ selectedImage: selectedImage })
            }
        }
    }

    async handleImageUpload(event) {
        const imageFile = event.target.files[0];

        if (imageFile === undefined) {
            this.setState({ selectedImage: null })
            return
        }

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1280,
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            const image = await this.fileToDataUri(compressedFile)
            const selectedImage = {
                extension: image.name.split('.')[1],
                image: image.base64.split(',')[1],  // base64 data only
                base64: image.base64
            }
            this.setState({ selectedImage: selectedImage })
        } catch (error) {
            console.log(error);
        }
    }

    fileToDataUri = (image) => {
        return new Promise((res) => {
            const reader = new FileReader()
            const { type, name, size } = image
            reader.addEventListener('load', () => {
                res({
                    base64: reader.result,
                    name: name,
                    type,
                    size: size,
                })
            })
            reader.readAsDataURL(image)
        })
    }

    render() {
        return (
            <div>
                <div className='main_qna_write'>
                    <div className='tab_container'>
                        <div className='input_form'>
                            <div className="input_title">이름</div>
                            <input
                                id="name"
                                name="name"
                                className="input_name"
                                type="text"
                                maxLength={20}
                                placeholder='이름을 입력해주세요'
                                onChange={this.onChangeQna}
                            />
                        </div>
                        <div className='input_form'>
                            <div className="input_title">전화번호</div>
                            <input
                                id="mobileNumber"
                                name="mobileNumber"
                                className="input_phone"
                                type="tel"
                                inputMode='tel'
                                maxLength={11}
                                value={this.state.qna['mobileNumber']}
                                pattern="[0-9]*"
                                placeholder='전화번호를 - 없이 숫자만 입력해주세요'
                                onInput={this.onChangeQna}
                            />
                        </div>
                        <div className='space_v20' />
                        <textarea
                            id="content"
                            name="content"
                            className='qna_input_content'
                            type="text"
                            placeholder="문의 내용을 상세하게 적어주시면 더욱 빨리 처리할 수 있습니다. (400자 이내)"
                            maxLength={400}
                            onChange={this.onChangeQna}
                        />
                    </div>
                    <div className='space_v20' />
                    <label className="input-file-button" for="input-file">
                        사진 첨부
                    </label>
                    <input type="file" id="input-file" name="사진 첨부" accept="image/jpeg, image/png" onChange={this.handleImageUpload} style={{ display: "none" }} />
                    <div className='space_v20' />
                    {
                        this.state.selectedImage !== null
                            ? <img
                                src={this.state.selectedImage.base64}
                                aria-hidden alt={"upload image"}
                                style={{ resizeMode: "contain", width: "100%", flex: "1" }}
                            />
                            : <></>
                    }
                </div>
                <footer>
                    {
                        this.state.loadingSave === true ?
                            <div className='spinner_container'>
                                <CircularProgress className="spinner" size='50px' />
                            </div>
                            : <button
                                className="button"
                                type="button"
                                disabled={!this.isEnableButtonCondition(this.state.qna)}
                                onClick={() => this.onClickedRegisteQna()}>
                                등록하기
                            </button>
                    }
                </footer>
                <Dialog
                    open={this.state.openAlert}
                    onClose={this.handleAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.alertTitle == null ? '' : this.state.alertTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.alertMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertClose}>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        )
    }
}

export default QnaSameDayWrite;