import React, { Component } from 'react';
import './style.css'
import QnaSameDayWrite from './qna_sameday_write';
import QnaSameDayList from './qna_sameday_list';

class QnaSameDayPage extends Component {
    constructor(props) {
        super(props);

        this.orderNumber = props.match.params.orderNumber
        console.log(this.orderNumber)

        this.state = {
            menu: this.getSearchParams('type') === 'list' ? 1 : 0,
        };
    }

    getSearchParams = (findValue) => {
        let search = this.props.location.search
        var params = search.substr(search.indexOf("?") + 1);
        var sval = "";
        params = params.split("&");
        for (var i = 0; i < params.length; i++) {
            var temp = params[i].split("=");
            if (temp[0] === findValue) {
                sval = temp[1];
            }
        }
        return sval;
    }

    changeMenu = (menuIndex) => {
        this.setState({ menu: menuIndex });
    }

    moveList = () => {
        this.setState({ menu: 1 });
    }

    render() {
        document.querySelector("title").innerText = "배송 문제 등록"
        return (
            <div>
                <div className="header_qna">
                    <div className="title">배송 문제 등록</div>
                    <ul className="tabs">
                        <li className={`${this.state.menu === 0 ? 'active' : ''}`} onClick={() => this.changeMenu(0)}>문의하기</li>
                        <li className={`${this.state.menu === 1 ? 'active' : ''}`} onClick={() => this.changeMenu(1)}>문의내역</li>
                    </ul>
                </div>
                {this.state.menu === 0 ?
                    <QnaSameDayWrite orderNumber={this.orderNumber}
                        moveList={this.moveList} /> :
                    <QnaSameDayList orderNumber={this.orderNumber} />
                }
            </div>
        )
    }
}

export default QnaSameDayPage;